import { throttle } from "lodash";
import { useEffect, useState } from "react";

export function useHasScrollbar(el: HTMLElement | null, throttleTime = 1) {
  const [state, setState] = useState({ horizontal: false, vertical: false });

  useEffect(() => {
    if (!el) {
      return;
    }

    setState({
      horizontal: el.scrollWidth > el.clientWidth,
      vertical: el.scrollHeight > el.clientHeight,
    });

    const handleResize = throttle(() => {
      setState({
        horizontal: el.scrollWidth > el.clientWidth,
        vertical: el.scrollHeight > el.clientHeight,
      });
    }, throttleTime);

    let resizeObserver: ResizeObserver | null = new ResizeObserver(() => handleResize());
    resizeObserver.observe(el);

    return () => {
      handleResize?.cancel();
      resizeObserver?.disconnect();
      resizeObserver = null;
    };
  }, [el]);

  return state;
}
