import React from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button, Typography, ButtonProps } from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "ui/atoms/LoadingButton";
import { Divider } from "ui/atoms/StackDivider";
import { CardActions, Card } from "ui/molecules/Card";
// import { CardActions, Card, Divider, LoadingButton } from "ui/atoms/Di";

export interface ConfirmDialogProps {
  messages?: Partial<{
    cancel: string;
    confirm: string;
  }>;
  title?: string;
  children: JSX.Element;
  onSubmit: () => Promise<void>;
  onClose?: () => void;
  hideDivider?: boolean;
  hideCancel?: boolean;
  confirmButtonProps?: Partial<LoadingButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
}
export const ConfirmDialogMessages: ConfirmDialogProps["messages"] = {
  cancel: "NO, CANCEL",
  confirm: "Yes, delete",
};

export function ConfirmDialog({
  messages = ConfirmDialogMessages,
  title = "CONFIRMATION",
  onSubmit,
  onClose,
  children,
  hideDivider,
  hideCancel,
  confirmButtonProps,
  cancelButtonProps,
}: ConfirmDialogProps) {
  messages = Object.assign({}, ConfirmDialogMessages, messages);
  const [state, fetch] = useAsyncFn(onSubmit, [onSubmit]);
  return (
    <Card>
      <Typography variant="h6">{title}</Typography>
      {children}
      {!hideDivider && <Divider />}
      <CardActions>
        <Button
          hidden={!!hideCancel}
          data-testid="confirm-dialog-cancel"
          color="primary"
          onClick={onClose}
          disabled={state.loading}
          {...cancelButtonProps}
        >
          {messages.cancel}
        </Button>
        <LoadingButton
          isLoading={state.loading}
          onClick={fetch}
          variant="contained"
          color="primary"
          {...confirmButtonProps}
          data-testid="confirm-dialog-submit"
        >
          {messages.confirm}
        </LoadingButton>
      </CardActions>
    </Card>
  );
}
