import React from "react";
import { MenuItem, Select } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { css } from "ui/css";
import { HStack } from "libs/layouts";
import { CheckBox } from "modules/endpoint-sensors/atoms";
import { tableMessages } from "./messages";
import { useTableState } from "./TableProvider";

const selectStyles = css({
  paddingTop: 3,
});

function SelectHeader({
  "data-testid": testId,
  hasSelectOnAllPages = true,
}: {
  "data-testid": string;
  "hasSelectOnAllPages"?: boolean;
}) {
  const {
    selection: {
      selectedSize,
      isAllSelected,
      isAllOnAllPageSelected,
      selectAll,
      toggleAll,
      selectOnAllPages,
    },
  } = useTableState();
  const onSelectChange = (
    e: React.ChangeEvent<{
      value: "current" | "all";
    }>
  ) => {
    if (e.target.value === "current") {
      selectAll();
    } else if (e.target.value === "all") {
      selectOnAllPages();
    }
  };
  return (
    <HStack data-testid={testId} space={-1}>
      <CheckBox
        indeterminate={selectedSize > 0 && !isAllSelected && !isAllOnAllPageSelected}
        checked={selectedSize > 0 || isAllOnAllPageSelected}
        onChange={toggleAll}
        style={{ marginRight: 0 }}
      ></CheckBox>
      {hasSelectOnAllPages && (
        <Select
          value=""
          margin="dense"
          disableUnderline
          variant="standard"
          classes={{
            select: selectStyles().className,
          }}
          onChange={onSelectChange as SelectInputProps["onChange"]}
          SelectDisplayProps={{ style: { paddingRight: 10 } }}
        >
          <MenuItem value="current">{tableMessages.allOnThisPage}</MenuItem>
          <MenuItem value="all">{tableMessages.allOnAllPages}</MenuItem>
        </Select>
      )}
    </HStack>
  );
}

export function TableSelectionCheckbox({ "data-testid": testId, row }: any) {
  const {
    selection: { isSelected, toggle },
  } = useTableState();
  return (
    <CheckBox
      data-testid={testId}
      checked={isSelected(row.value)}
      onChange={() => toggle(row.value)}
    />
  );
}

TableSelectionCheckbox.Header = SelectHeader;
